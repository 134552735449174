import axios from 'axios';
import { message } from 'ant-design-vue';
import i18n from '@/plugin/i18n';
const {
  t
} = i18n.global;
export default defineComponent({
  name: 'SocialLoginCallback',
  mounted() {
    try {
      const request = axios.create({
        baseURL: process.env.VUE_APP_BASE_API,
        withCredentials: false,
        timeout: 60000
      });
      const urlParams = new URLSearchParams(window.location.search);
      const queryString = urlParams.toString().length > 0 ? `?${urlParams.toString()}` : '';
      const platform = this.$route.params.platform;
      request.post(`/eam-api/oauth/${platform}${queryString}`).then(response => {
        const isAuthSuccessful = response.data.success;
        if (isAuthSuccessful) {
          const successMessage = t('message.oauth_success');
          message.success(successMessage);
        } else {
          const errorMessage = get(response, 'data.message', t('message.oauth_error'));
          message.error(errorMessage);
        }
      }).catch(error => {
        const errorMessage = get(error, 'response.data.message', t('message.oauth_error'));
        message.error(errorMessage);
      });
    } catch (e) {}
  }
});