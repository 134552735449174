import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dc7d1afa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu-title" }
const _hoisted_2 = { class: "menu-title" }
const _hoisted_3 = { class: "menu-title" }
const _hoisted_4 = { class: "menu-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_sub_menu = _resolveComponent("a-sub-menu")!
  const _component_a_menu = _resolveComponent("a-menu")!

  return (_openBlock(), _createBlock(_component_a_menu, {
    mode: "inline",
    class: "menu-container",
    selectedKeys: _ctx.selectedKeys,
    "onUpdate:selectedKeys": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedKeys) = $event)),
    "open-keys": _ctx.openKeys
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (menu) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (menu.type == 'single')
            ? (_openBlock(), _createBlock(_component_a_menu_item, {
                key: menu.router
              }, {
                default: _withCtx(() => [
                  (menu.router == '/article-console')
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "item-link",
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.gotoNewsArticleConsole && _ctx.gotoNewsArticleConsole(...args)))
                      }, [
                        _createVNode(_component_base_icon, {
                          size: "24",
                          name: menu.icon
                        }, null, 8, ["name"]),
                        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t(menu.title)), 1)
                      ]))
                    : (_openBlock(), _createBlock(_component_router_link, {
                        key: 1,
                        to: menu.router,
                        class: "item-link"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_base_icon, {
                            size: "24",
                            name: menu.icon
                          }, null, 8, ["name"]),
                          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t(menu.title)), 1)
                        ]),
                        _: 2
                      }, 1032, ["to"]))
                ]),
                _: 2
              }, 1024))
            : (_openBlock(), _createBlock(_component_a_sub_menu, {
                key: menu.router
              }, {
                title: _withCtx(() => [
                  _createVNode(_component_base_icon, {
                    size: "24",
                    name: menu.icon
                  }, null, 8, ["name"]),
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t(menu.title)), 1)
                ]),
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menu.children, (subItem) => {
                    return (_openBlock(), _createBlock(_component_a_menu_item, {
                      key: subItem.router
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, {
                          to: subItem.router,
                          class: "item-link"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t(subItem.title)), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 2
              }, 1024))
        ], 64))
      }), 256))
    ]),
    _: 1
  }, 8, ["selectedKeys", "open-keys"]))
}